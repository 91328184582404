import Divider from '../../Divider.jsx'
import { dashatize } from './Destinations'

const DestinationsGuide = ({
  onHide,
  show,
  currentStep,
  destination,
  organizationId,
}) => {
  // const guides = {
  //   1: <ManagedGuide onHide={onHide} show={show} />,
  //   2: (
  //     <UnmanagedGuide
  //       onHide={onHide}
  //       show={show}
  //       organizationId={organizationId}
  //       destination={destination}
  //     />
  //   ),
  //   3: (
  //     <DestinationGuide
  //       onHide={onHide}
  //       show={show}
  //       destination={destination}
  //     />
  //   ),
  // }

  if (!show) {
    return <></>
  }
  return (
    <>
      {destination?.managed && currentStep === 1 ? (
        <ManagedGuide onHide={onHide} show={show} />
      ) : (
        <UnmanagedGuide
          onHide={onHide}
          show={show}
          destination={destination}
          organizationId={organizationId}
        />
      )}
      {currentStep === 2 && (
        <DetailedGuide onHide={onHide} show={show} destination={destination} />
      )}
    </>
  )
}

const ManagedGuide = ({ onHide, show }) => {
  return (
    <div className={`guide ${show ? 'show' : ''}`}>
      <button onClick={() => onHide()} className="close-button">
        &times;
      </button>
      <h1>Managed by Maya</h1>
      <Divider spaceY={10} />
      <div className="step-heading"></div>
      <ol className="no-number">
        <li>
          With this option you are setting the database to be managed by Maya.
          This means that extracted data from all configured sources will be
          managed by Maya using our Bigquery. The data will be hosted inside the{' '}
          <b>EU</b> location.
          <br />
          <br />
          In order to access the database, configure the email access through
          the Shared Dataset option underline <u>Developer Settings</u>.
        </li>
        <li>
          ⚠️Please note that once a data source is set, the destination can’t be
          altered.
        </li>
      </ol>
      <div className="step-heading"></div>
    </div>
  )
}

const UnmanagedGuide = ({ onHide, show, organizationId, destination }) => {
  const guides = {
    'big-query': (
      <UnmanagedBigQuery
        onHide={onHide}
        show={show}
        organizationId={organizationId}
      />
    ),
    databricks: (
      <UnmanagedDatabricks
        onHide={onHide}
        show={show}
        organizationId={organizationId}
      />
    ),
    '': <></>,
  }
  return guides[dashatize(destination?.name)] || <></>
}

const UnmanagedBigQuery = ({ show, onHide, organizationId }) => {
  return (
    <div className={`guide ${show ? 'show' : ''}`}>
      <button onClick={() => onHide()} className="close-button">
        &times;
      </button>
      <h1>Managed by your organization</h1>
      <Divider spaceY={10} />
      <div className="step-heading"></div>
      <ol className="no-number">
        <li>
          With this option, your organization will manage the database, and all
          data will be directly transferred to your BigQuery project. This setup
          ensures that the data from all configured sources is maintained within
          a system controlled by your organization.
        </li>
        <li>
          In order to set up the destination, you will need to set up the
          bigquery database. Before you begin, make sure your account has access
          to your organization's Google Cloud project with at least{' '}
          <b>Service Account Admin</b> and <b>BigQuery Admin</b> rights.
          <br />
          <br />
          <b>Note:</b> If you do not have a BigQuery project, you will need to
          create one before proceeding. Remember to also arrange a billing
          method, as the free trial does not cover data storage needs.
          <br />
          <br />
          <div className="img-item">
            <img
              src="https://old.mayainsights.com/static/img/icons/dest-guide-01.png"
              alt=""
              width="500"
            />
          </div>
          <ul>
            ✅ Step A: Go to{' '}
            <a
              target="_blank"
              style={{ textDecoration: 'underline' }}
              href="https://console.cloud.google.com/projectselector2/iam-admin/serviceaccounts?supportedpurview=project"
            >
              service account
            </a>
            . Click on <b>CREATE SERVICE ACCOUNT</b>
            <ol>
              <li>
                <b>Service account details</b>: Fill in the service account
                details by giving the account a service account name of your
                choice.
                <div className="img-item">
                  <img
                    src="https://old.mayainsights.com/static/img/icons/dest-guide-02.png"
                    alt=""
                    width="500"
                  />
                </div>
              </li>
              <li>
                <b>Grant this service account access to project</b>: Set the
                permissions <b>BigQuery Job User</b> and{' '}
                <b>BigQuery Read Session User</b>.
                <div className="img-item">
                  <img
                    src="https://old.mayainsights.com/static/img/icons/dest-guide-03.png"
                    alt=""
                    width="500"
                  />
                </div>
              </li>
              <li>
                <b>Grant users access to this service account</b>: no specific
                input is required. Just click done.
              </li>
              <li>
                Back in{' '}
                <a
                  target="_blank"
                  style={{ textDecoration: 'underline' }}
                  href="https://console.cloud.google.com/projectselector2/iam-admin/serviceaccounts?supportedpurview=project"
                >
                  service account
                </a>{' '}
                page, select your project, copy the service account email (i.e.
                column email) created to be used in the next step.
              </li>
            </ol>
            <br />✅ Step B: Go to Navigation Menu→BigQuery→
            <a
              href="https://console.cloud.google.com/bigquery"
              target="_blank"
              style={{ textDecoration: 'underline' }}
            >
              BigQuery Studio
            </a>
            .
            <br />
            You may be prompted to enable the Big Query API in order to proceed.
            Click on Enable.
            <ol>
              <li>
                In the <b>Explorer</b> panel, select the project where you want
                to create the dataset.
              </li>
              <li>
                Expand the <b>More Actions</b> option and click{' '}
                <b>Create dataset</b> (Screenshot below).
                <div className="img-item">
                  <img
                    src="https://old.mayainsights.com/static/img/icons/dest-guide-04.png"
                    alt=""
                    width="500"
                  />
                </div>
              </li>
              <li>
                On dataset_id input{' '}
                <span style={{ color: '#739570' }}>
                  prj_{organizationId}_db
                </span>{' '}
                and select the region/multi-region where you want to save your
                data. (Preferably somewhere close to your location or the
                location you already have your data stored in) Click{' '}
                <b>CREATE DATASET</b>.
              </li>
              <li>
                Click on the created dataset on the <b>Explorer</b> panel. Click
                on Sharing → Permissions → ADD PRINCIPAL → Then add the service
                account email copied from step B4 on New principals field, with
                role <b>BigQuery Admin</b>, and click Save.
                <div className="img-item">
                  <img
                    src="https://old.mayainsights.com/static/img/icons/dest-guide-05.png"
                    alt=""
                    width="500"
                  />
                </div>
                <br />
                <div className="img-item">
                  <img
                    src="https://old.mayainsights.com/static/img/icons/dest-guide-06.jpg"
                    alt=""
                    width="500"
                  />
                </div>
              </li>
            </ol>
          </ul>
        </li>
        <li>
          ⚠️ Please note that once a data source is set, the destination can’t
          be altered.
        </li>
      </ol>
      <div className="step-heading"></div>
    </div>
  )
}

const UnmanagedDatabricks = ({ show, onHide, organizationId }) => {
  return (
    <div className={`guide ${show ? 'show' : ''}`}>
      <button onClick={() => onHide()} className="close-button">
        &times;
      </button>
      <h1>Setting of OAuth m2m between Databricks and Azure</h1>
      <Divider spaceY={10} />
      <p className="bold nm">Prerequisites</p>
      <ol>
        <li>Databricks workspace to be provisioned in Azure Subscription</li>
        <li>Data Lake storage needs to be provisioned in Azure Subscription</li>
      </ol>
      <Divider spaceY={10} />
      <ol className="">
        <li>
          <p className="bold">
            Setting OAuth m2m authentication between Azure and Databricks
          </p>
          <ol>
            <li>
              Create an <span className="underline">Enterprise Application</span>
              <div className="img-item">
                <img
                  src="https://old.mayainsights.com/static/img/icons/databricks-dest-guide-01.png"
                  alt=""
                  width="500"
                />
              </div>
            </li>
            <li>
              <div className="img-item">
                <img
                  src="https://old.mayainsights.com/static/img/icons/databricks-dest-guide-02.png"
                  alt=""
                  width="500"
                />
              </div>
            </li>
            <li>
              <div className="img-item">
                <img
                  src="https://old.mayainsights.com/static/img/icons/databricks-dest-guide-03.png"
                  alt=""
                  width="500"
                />
              </div>
            </li>
            <li>
              Copy the <span className="success">Application ID</span> and register it as a service account in your
              Databricks Workspace
              <div className="img-item">
                <img
                  src="https://old.mayainsights.com/static/img/icons/databricks-dest-guide-04.png"
                  alt=""
                  width="500"
                />
              </div>
            </li>
            <li>
              <div className="img-item">
                <img
                  src="https://old.mayainsights.com/static/img/icons/databricks-dest-guide-05.png"
                  alt=""
                  width="500"
                />
              </div>
              <span className="subtitle-text underline">
                Allow cluster creation should be checked
              </span>
            </li>
          </ol>
        </li>

        <li>
          <p className="bold">
            Datalake storage needs to be mounted in Databricks Workspace - see{' '}
            <a href="#appendix">Appendix</a>
          </p>
          <span className="subtitle-text underline">
            *Databricks clusters are auto-created during ETL Runs
          </span>
          <div id="appendix"></div>
        </li>
      </ol>
      <p className="bold underline">
        ⚠️ Please note that once a data source is set, the destination can’t be
        altered.
      </p>
    </div>
  )
}

const DetailedGuide = ({ onHide, show, destination }) => {
  const guides = {
    'big-query': <BigQueryDetailed onHide={onHide} show={show} />,
    databricks: <DatabricksDetailed onHide={onHide} show={show} />,
    '': <></>,
  }
  return guides[dashatize(destination?.name)] || <></>
}

const BigQueryDetailed = ({ onHide, show }) => {
  return (
    <div className={`guide ${show ? 'show' : ''}`}>
      <button onClick={() => onHide()} className="close-button">
        &times;
      </button>
      <h1>Service accounts</h1>
      <Divider spaceY={10} />
      <div className="step-heading"></div>
      <ol className="no-number">
        {/* the databse name should be dynamic in the future */}
        <li>
          Provide the service account credentials for Maya to transmit the data
          to your created <b>Google BigQuery</b> database:
        </li>
        <Divider spaceY={10} />
        <li>
          On the Service Account page → Keys → Add Key → Select in{' '}
          <b>JSON format</b> → store the credentials and send them through
          Maya’s interface.
        </li>
        <li>
          After providing the credentials you will be prompted to select a
          region/multi-region. Select the one that you chose during the database
          creation process.
        </li>
      </ol>
      <div className="step-heading"></div>
    </div>
  )
}

const DatabricksDetailed = ({ onHide, show }) => {
  return (
    <div className={`guide ${show ? 'show' : ''}`}>
      <button onClick={() => onHide()} className="close-button">
        &times;
      </button>
      <h1>Databricks</h1>
      <Divider spaceY={10} />
      <ol className="guide-steps-list">
        <li className="guide-step">
          <span>Host</span> Login to Databricks workspace
          <ol>
            <li>
              Copy the workspace url
              <ol>
                <li>
                  Example{' '}
                  <a href="#">
                    https://adb-1111111111111111.4.azuredatabricks.net/
                  </a>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li className="guide-step">
          <span>Azure Workspace Resource ID</span> Login to Azure portal
          <ol>
            <li>Open Databricks resource</li>
            <li>Go to Settings/Properties</li>
            <li>
              Copy the “Managed resource group id”
              <ol>
                <li>
                  Example:{' '}
                  <a href="#">
                    /subscriptions/aaaaaaaa-bbbb-aaaa-bbbb-aaaaaaaa/resourceGroups/aaaaa
                  </a>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li className="guide-step">
          <span>Azure Client ID</span> In Azure portal register Enterprise
          Application
          <ol>
            <li>Copy the Application ID</li>
          </ol>
        </li>
        <li className="guide-step">
          <span>Azure Client Secret</span> In Azure portal open App registration
          <ol>
            <li>Find the newly registered app and generate a secret</li>
          </ol>
        </li>
        <li className="guide-step">
          <span>Azure Tenant ID</span> In Azure portal open Entra ID
          <ol>
            <li>Go to properties</li>
            <li>Copy tenant Id</li>
          </ol>
        </li>
        <li>
          In the end, you should have compiled the following information
          <ol>
            <li>
              <pre style={{ 'white-space': 'break-word', overflow: 'scroll' }}>
                {/* <p style={{background: "#d3d3d3", borderRadius: '8px', margin: 0}}> */}
                {'{'}
                "host" : "https://adb-1111111111111111.4.azuredatabricks.net/",
                "azure_workspace_resource_id" :
                "/subscriptions/aaaaaaaa-bbbb-aaaa-bbbb-aaaaaaaa/resourceGroups/aaaaa
                ", "azure_client_secret" :
                "Wea7B~xcOwaBw.Jdfqu3hBnVABFmOUnwp18rIMaLV", "azure_client_id" :
                "efd0c17c-1ab1-4b29-b34d-8ff239989fe0", "azure_tenant_id" :
                "aaaaaaaa-bbbb-ffff-gggg-aaaaaaaa", "auth_type" :
                "azure-client-secret"
                {'}'}
                {/* </p> */}
              </pre>
            </li>
          </ol>
        </li>
        <li>Match the following fields with the acquired credentials</li>
      </ol>
      <div className="step-heading"></div>
    </div>
  )
}

export default DestinationsGuide
